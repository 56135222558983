import React from "react";
import "./Contact.scss";

import { ContactForm } from "./ContactForm";

export function Contact() {
  return (
    <div className='contact'>
      <div className='form-container'>
        <div className='form-title'> Send us a message </div>
        <ContactForm />
      </div>
    </div>
  );
}
