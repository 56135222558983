import React from "react";
import "./Button.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Button(props) {
  const { onClick, text, iconName } = props;
  return (
    <button className='btn' onClick={onClick}>
      <FontAwesomeIcon icon={iconName} />
      <div className='btn-text'>{text}</div>
    </button>
  );
}
