import React from "react";
import gif from "../../Assets/About/about-backgroundGif.gif";
import "./About.scss";

export function About() {
  return (
    <div className='about'>
      <div className='upper-gif-container'>
        <img className='gif' src={gif} />
      </div>

      <div className='textbox-container'>
        <div className='text-container'>
          <div className='paragraph-container'>
            <div className='text'>
              Vixi is a dynamic and innovative production company that
              specializes in creating unforgettable live experiences. From music
              to comedy, our events bring together diverse audiences and connect
              them through the universal language of laughter and rhythm.
            </div>

            <div className='text'>
              <div>
                At Vixi, we believe in the power of live performances to
                inspire, entertain, and unite people. Our team of experts is
                dedicated to meticulously planning and executing each event,
                ensuring every detail is perfect from the first act to the final
                curtain call.
              </div>
            </div>

            <div className='text'>
              <div>
                In today's digital age, we leverage the latest digital marketing
                strategies to sell out our shows. We utilize a unique approach
                that combines these modern tactics with creative strategies,
                such as our Exposure Reimbursement Concert Strategy (ERCS), to
                maximize brand exposure while minimizing financial risk.
              </div>
            </div>

            <div className='text'>
              <div>
                Beyond organizing events, we are committed to helping our
                clients reach their potential. Our marketing efforts are
                designed not just to fill seats, but to create a buzz that
                extends far beyond the venue.
              </div>
            </div>

            <div className='text'>
              <div>
                With a proven track record of successful shows, a passionate
                team who loves what they do, and a cutting-edge approach to
                event promotion, Vixi is your trusted partner for memorable
                events that resonate with audiences and achieve your business
                objectives.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bottom-gif-container'>
        <img className='gif' src={gif} />
      </div>
    </div>
  );
}
