import React from "react";
import "./LogoHover.scss";
import logo from "../../Assets/Home/Logo.png";
import shapes from "../../Assets/Home/shapes.png";

export function LogoHover() {
  return (
    <div className='logo-container'>
      <img className='logo' src={logo} />
      <div className='shapes-overlay'>
        <img className='shapes' src={shapes} />
      </div>
    </div>
  );
}
