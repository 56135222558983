import React from "react";
import "./Input.scss";

export function Input(props) {
  return (
    <div className='input-container'>
      <input
        className='input'
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}
        required={props.required}></input>
    </div>
  );
}
