import React, { useEffect, useState } from "react";
import logo from "../../Assets/vixi-logo.png";
import { BiMenuAltRight } from "react-icons/bi";

import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";

export function Header() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
    setMenuOpen(true);
  };
  const { pathname } = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  return (
    <header className='header'>
      <div className='header_content'>
        <div className='header_content_logo'>
          <img hidden={pathname === "/"} src={logo} onClick={navigateToHome} />
        </div>
        <nav
          className={`header_content_nav ${
            menuOpen && size.width < 768 ? "isMenu" : ""
          }`}>
          <ul>
            <li>
              <Link onClick={menuToggleHandler} to='/about'>
                About
              </Link>
            </li>
            <li>
              <Link onClick={menuToggleHandler} to='/contact'>
                Contact
              </Link>
            </li>
            {/* <li>
              <Link onClick={menuToggleHandler} to='/team'>
                Time
              </Link>
            </li> */}
            <li>
              <Link
                onClick={menuToggleHandler}
                to='https://linktr.ee/vixi.productions'>
                Events & Tickets
              </Link>
            </li>
          </ul>
        </nav>
        <div className='header_content_toggle'>
          {menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
}
