import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let callToast = (type, content, iconName) => {
  switch (type) {
    case toastTypes.INFO:
      toast.info(content, {
        icon: <FontAwesomeIcon icon={iconName ?? "circle-info"} />,
        theme: "dark",
        position: "top-center",
      });
      break;
    case toastTypes.SUCCESS:
      toast.success(content, {
        icon: <FontAwesomeIcon icon={iconName ?? "circle-check"} />,
        theme: "dark",
        position: "top-center",
      });
      break;
    case toastTypes.WARNING:
      toast.warning(content, {
        icon: <FontAwesomeIcon icon={iconName ?? "triangle-exclamation"} />,
        theme: "dark",
        position: "top-center",
      });
      break;
    case toastTypes.ERROR:
      toast.error(content, {
        icon: <FontAwesomeIcon icon={iconName ?? "circle-xmark"} />,
        theme: "dark",
        position: "top-center",
      });
      break;
    default:
      toast(content, {
        icon: iconName ? <FontAwesomeIcon icon={iconName} /> : null,
        theme: "dark",
        position: "top-center",
      });
      break;
  }
};

let toastTypes = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  DEFAULT: "default",
};

export { callToast, toastTypes };
