import { api } from "../config/axiosConfig.js";

export const TeamMemberApi = {
  get: async function (req = { body: {} }) {
    const { body } = req;
    const response = await api.get("/teammembers", body);
    return response.data;
  },
  post: async function (req) {
    const { body } = req;
    const response = await api.post("/teammembers", body);
    return response.data;
  },
  put: async function (req) {
    const { body, params } = req;
    const { id } = params;
    const response = await api.put(`/teammembers/${id}`, body);
    return response.data;
  },
  delete: async function (req) {
    const { id } = req.body;
    const response = await api.delete("/teammembers", { id });
    return response.data;
  },
};
