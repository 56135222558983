import "./App.scss";
import { Footer, Header } from "./Components";
import { Contact, Home, Login, About, NotFound } from "./Views";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPaperPlane,
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
  faCircleInfo,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faPaperPlane,
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
  faCircleInfo,
  faCircleNotch
);

function App() {
  return (
    <div className='App'>
      <Header />
      <div className='view'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/login' element={<Login />} />
          {/* <Route path='/team' element={<Team />} /> */}
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </div>
      <Footer className='footer' />
      <ToastContainer />
    </div>
  );
}

export default App;
