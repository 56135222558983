import React from "react";
import "./TextArea.scss";

export function TextArea(props) {
  return (
    <div className='text-area-container'>
      <textarea
        className='text-area'
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}></textarea>
    </div>
  );
}
