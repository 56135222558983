import React, { useState } from "react";
import "./ContactForm.scss";

import { Input } from "../../Components/Input/Input";
import { TextArea } from "../../Components/TextArea/TextArea";
import { Button } from "../../Components/Button/Button";
import { MessageApi } from "../../Apis/Message/Message.api";

import { callToast, toastTypes } from "../../Utils/Utils.js";

export function ContactForm() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [mail, setMail] = useState("");
  const [mailError, setMailError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const resetFields = () => {
    setName("");
    setMail("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any of the fields are empty
    if (!name || !mail || !message) {
      callToast(toastTypes.ERROR, "Please enter all fields");
      return;
    }

    validateName(name);
    validateMail(mail);
    validateMessage(message);
    if (!nameError && !mailError && !messageError) {
      MessageApi.post({
        body: {
          senderName: name,
          senderMail: mail,
          text: message,
        },
      })
        .then(() => {
          callToast(toastTypes.SUCCESS, "Your message was sent");
          resetFields();
        })
        .catch((error) => {
          callToast(toastTypes.ERROR, "Unable to save message");
          console.error(error);
        });
    } else {
      const errorMessage = `${nameError ? nameError + "\n" : ""}${
        mailError ? mailError + "\n" : ""
      }${messageError ? messageError : ""}`;
      callToast(toastTypes.ERROR, errorMessage);
    }
  };

  const validateName = (name) => {
    if (!name) {
      setNameError("The name is required.");
    } else if (!/^[a-zA-Z ]{1,32}$/.test(name)) {
      setNameError("The name must be a maximum of 32 alphabetic characters.");
    } else {
      setNameError("");
    }
  };

  const validateMail = (mail) => {
    if (!mail) {
      setMailError("Email is required.");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
      setMailError("The email must be valid.");
    } else if (mail.length > 50) {
      setMailError(" The email must have a maximum of 50 characters.");
    } else {
      setMailError("");
    }
  };

  const validateMessage = (message) => {
    if (!message) {
      setMessageError("Message is required.");
    } else if (message.length > 500) {
      setMessageError("The message must have a maximum of 500 characters.");
    } else {
      setMessageError("");
    }
  };

  return (
    <div className='contact-form'>
      <form onSubmit={handleSubmit}>
        <div className='name-input'>
          <Input
            label={"Name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              validateName(e.target.value);
            }}
          />
          {nameError && <div className='error'>*{nameError}</div>}
        </div>
        <div className='mail-input'>
          <Input
            label={"Email"}
            value={mail}
            onChange={(e) => {
              setMail(e.target.value);
              validateMail(e.target.value);
            }}
          />
          {mailError && <div className='error'>*{mailError}</div>}
        </div>
        <div className='message-input'>
          <TextArea
            label={"Leave your message here"}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              validateMessage(e.target.value);
            }}
          />
          <div className='char-count'>{message.length}/500</div>
          {messageError && <div className='error'>*{messageError}</div>}
        </div>
        <div className='button-container'>
          <Button iconName='paper-plane' text='Send Message' />
        </div>
      </form>
    </div>
  );
}
