import React from "react";
import "./Footer.scss";

export function Footer() {
  return (
    <div className='footer'>
      <div className='copyright'>
        © 2023 - Vixi Company, Inc. All rights reserved. Boston Massachusetts
      </div>
      <div className='social-links'>
        <a
          onClick={() =>
            window.open("https://www.instagram.com/vixiproductions/", "_blank")
          }>
          Instagram
        </a>{" "}
        |{" "}
        <a
          onClick={() =>
            window.open("https://www.tiktok.com/@vixiproduction", "_blank")
          }>
          TikTok
        </a>{" "}
        |{" "}
        <a
          onClick={() =>
            window.open("https://www.facebook.com/vixiproductions", "_blank")
          }>
          Facebook
        </a>
      </div>
    </div>
  );
}
