import { api } from "../config/axiosConfig.js";

export const MessageApi = {
  get: async function (req) {
    const { body } = req;
    const response = await api.get("/messages", body);
    return response.data;
  },
  post: async function (req) {
    const { body } = req;
    const response = await api.post("/messages", body);
    return response.data;
  },
  delete: async function (req) {
    const { id } = req.body;
    const response = await api.delete("/messages", { id });
    return response.data;
  },
};
