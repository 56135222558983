import React from "react";
import { LogoHover } from "../../Components/LogoHover/LogoHover";
import "./Home.scss";

export function Home() {
  return (
    <div className='home'>
      <LogoHover />
      <div className='textbox-container'>
        <div className='text-container'>
          <div className='text'>
            Vixi is a dynamic and innovative production company that specializes
            in creating unforgettable live experiences. From music to comedy,
            our events bring together diverse audiences and connect them through
            the universal language of laughter and rhythm.
            <p>
              At Vixi, we believe in the power of live performances to inspire,
              entertain, and unite people. Our team of experts is dedicated to
              meticulously planning and executing each event, ensuring every
              detail is perfect from the first act to the final curtain call.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
