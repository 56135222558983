import React, { useEffect } from "react";
import "./NotFound.scss";
import { useNavigate } from "react-router-dom";
import gif from "../../Assets/NotFound/man-walking-inverted.gif";

export function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/*");
  }, [navigate]);

  return (
    <div className='not-found'>
      <div className='gif-container'>
        <img className='gif' src={gif} />
      </div>

      <div className='text-container'>
        <div className='title'>404</div>
        <div className='description'>PAGE NOT FOUND</div>
      </div>

      <div className='gif-container'>
        <img className='gif right' src={gif} />
      </div>
    </div>
  );
}
